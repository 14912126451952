import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

import { CmsService } from 'lib/services/cms.service';
import { MetaService } from 'lib/services/meta.service';
import { WebpService } from 'lib/services/webp.service';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import Typed from 'typed.js';
import { isBrowser } from 'lib/tools';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { ApiService } from 'lib/services/api.service'; // test code, delete

const scriptName = 'cms-homepage.component';

@Component({
    selector: 'app-cms-homepage',
    templateUrl: './cms-homepage.component.html',
    styleUrls: ['./cms-homepage.component.scss'],
})
export class CmsHomepageComponent extends ExxComComponentClass implements OnInit {
    document: Document | undefined;
    entry: any = {};
    breakpointObserver: BreakpointObserver;
    cmsService: CmsService;
    metaService: MetaService;
    router: Router;
    currentWord: string = '';
    mdfWidth: 'full' | 'half';
    isMDFFullWidth: boolean;
    getWebpImg: (src: string) => string;

    references = [
        'content.use_cases',
        'hero_banner_content.hero_banner.mdf_references.mdf',
        'content.product_tiles',
        'content.product_tiles.tile_group.tile_row.tile.tile_reference.home_page_product_tiles',
        'content.logos',
        'content.homepage_configurations',
        'content.cta_banner',
        'homepage_reordering.group.logos',
        'homepage_reordering.group.use_cases',
        'homepage_reordering.group.product_tile_rows.tile_group.tile_row.tile.tile_reference.home_page_product_tiles',
        'homepage_reordering.group.homepage_configurations.homepage_configurations',
        'homepage_reordering.group.cta_banner',
    ];

    wordCarousel: string[] = [];

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private _doc: any,
        b: BreakpointObserver,
        c: CmsService,
        m: MetaService,
        w: WebpService,
        private apiService: ApiService // test code, delete
    ) {
        super({
            dependencies: {
                breakpointObserver: b,
                cmsService: c,
                metaService: m,
            },
        });
        this.getWebpImg = (src: string) => w.getWebpImg(src);
        if (isPlatformBrowser(this.platformId)) {
            this.document = _doc;
        }
    }
    async intializeEntry() {
        try {
            // const testEntry = await this.cmsService.getEntries('category_page');
            // console.log(testEntry);

            // const testEntry2 = await this.cmsService.getEntries('category_page', { skip: 100 });
            // console.log(testEntry2);

            // const testEntry3 = await this.cmsService.getEntries('category_page', { skip: 200 });
            // console.log(testEntry3);

            const res = await this.apiService.get('categorypage'); // test code
            console.log(res);

            this.entry = await this.cmsService.getPage('homepage', {
                references: this.references,
            });
            if (!this.entry) {
                return;
            }
            this.mdfWidth = this.get(this.entry, 'hero_banner_content.hero_banner.mdf_references.mdf').length == 1 ? 'full' : 'half';
            this.wordCarousel = this.entry?.hero_banner_content.hero_banner?.hero_banner_word_carousel?.carousel_word;
            if (!this.isEmpty(this.wordCarousel) && isPlatformBrowser(this.platformId)) await this.createTyping(this.wordCarousel);
        } catch (err) {
            console.error(...new ExxComError(623235, scriptName, err).stamp());
        }
    }

    navigateByUrl(link: string) {
        try {
            this.router.navigateByUrl('/' + link);
        } catch (err) {
            console.error(...new ExxComError(666695, scriptName, err).stamp());
        }
    }

    async createTyping(words: string[]) {
        try {
            new Typed('#carousel', {
                strings: words,
                typeSpeed: 50,
                loop: true,
                backDelay: 1000,
                showCursor: false,
            });
        } catch (err) {
            console.error(...new ExxComError(112195, scriptName, err).stamp());
        }
    }

    ngOnInit() {
        try {
            this.intializeEntry();
            if (!this.entry) {
                return;
            }
        } catch (err) {
            console.error(...new ExxComError(655595, scriptName, err).stamp());
        }
    }
}
