import { Component, Inject, Input, OnInit } from '@angular/core';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { CategoryService } from 'lib/services/category/category.service';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { Location } from '@angular/common';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';
import { ConfiguratorService } from 'lib/services/configuration/configurator.service';

const scriptName = 'cms-partial-category-top-feature-solutions.component';

@Component({
    selector: 'cms-partial-category-top-feature-solutions',
    templateUrl: './feature-solutions.component.html',
    styleUrls: ['./feature-solutions.component.scss'],
})
export class FeatureSolutionsComponent extends CmsPartialComponentClass implements OnInit {
    @Input() featureSolutionsRow: any;
    @Input() dataUrl: string;
    @Input() dataTitle: string;
    @Input() solutionID: string;
    @Input() grayBackgroundRows: any = [];
    getWebpImg: (src: string) => string;
    configuratorService: ConfiguratorService;

    constructor(
        @Inject('environment') environment: any,
        public bootstrapMarketoService: BootstrapMarketoService,
        routerService: RouterService,
        webpService: WebpService,
        location: Location,
        categoryService: CategoryService,
        configuratorService: ConfiguratorService
    ) {
        super({
            dependencies: {
                environment,
                bootstrapMarketoService,
                routerService,
                location,
                categoryService,
                webpService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
        this.configuratorService = configuratorService;
    }

    async ngOnInit() {
        if (this.featureSolutionsRow.feature_solutions.feature_solutions[0].feature_solutions) {
            const solutions = this.featureSolutionsRow.feature_solutions.feature_solutions[0].feature_solutions;

            // Then, place the products on the correct solution
            await solutions.forEach(async (solution) => {
                // Check for solution on exxcom config_v2 db
                const solutionExxcom = await this.configuratorService.getConfigurationByUrlComponent(
                    'exx',
                    solution.part_number,
                    null,
                    null,
                    true,
                    true
                );

                if (solutionExxcom && solutionExxcom[0] && solutionExxcom[0].isActive && solutionExxcom[0].sierraActive) {
                    solution.sierraActive = true;
                    const exxConfig = await this.configuratorService.getConfigurationWithBasePrice('exx', solutionExxcom[0].mpn);
                    if (solution.product) solution.product.price = exxConfig[0]?.optimizedConfigurations?.good?.priceTotal;
                } else {
                    solution.sierraActive = false;
                }
            });
            // feather.replace();
        } else {
            console.log('No feature solutions to process.');
        }
    }

    getBackgroundImage(index: number) {
        try {
            const rates = ['good', 'better', 'best'];
            return `https://assets.exxactcorp.com/img/exx/cms/system-images/${rates[index]}.png`;
        } catch (err) {
            console.error(...new ExxComError(213321, scriptName, err).stamp());
        }
    }

    splitWord(title: string): { firstPart: string; lastPart: string } {
        const words = title.trim().split(' ');
        const lastWord = words.pop() || '';
        const firstPart = words.join(' ');
        return { firstPart, lastPart: lastWord };
    }

    onButtonClick(event: Event) {
        event.stopPropagation();
        // event.preventDefault();
        // Add any additional logic you need here
    }
}
